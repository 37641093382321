import { useUserApi } from '~/api/user'
import useModal from '~/composables/useModal'
import { useValidate } from '~/composables/useValidate'
import { PAGE_URLS } from '~/config/page-url'
import { MODAL_TYPES } from '~/config/constant'
import { RESET_PASSWORD_TOAST } from '~/constants/toast'

interface ResetPasswordForm {
  username: string;
  confirmPassword: string;
  token: string;
}
export const useResetPassword = () => {
  const route = useRoute()
  const { closeAllModal, openModal } = useModal()
  const { validateRefs } = useValidate()
  const { RESET_PASSWORD } = useUserApi()
  const { $axios, $alertMsg } = useNuxtApp()

  const password = ref<string>('')
  const confirmPassword = ref<string>('')
  const passwordRef = ref<(HTMLElement & { resetValidate: () => boolean }) | null>(null)
  const confirmPasswordRef = ref<(HTMLElement & { resetValidate: () => boolean }) | null>(null)
  const isLoadingSubmit = ref<boolean>(false)
  const formData: ComputedRef<{
    password: string;
    confirmPassword: HTMLElement | null;
    token: string | null | LocationQueryValue[] | undefined
  }> = computed(() => ({
    password: password.value,
    confirmPassword: confirmPassword.value,
    token: route.query?.token
  }))

  const submit = async () => {
    if (isLoadingSubmit.value) { return }
    try {
      isLoadingSubmit.value = true
      const isValid: boolean = validateRefs([passwordRef, confirmPasswordRef])
      if (isValid) {
        const { data: response } = await $axios.post(RESET_PASSWORD, { ...formData.value }, { withCredentials: true })
        if (response && response.status === 'OK') {
          $alertMsg('success', RESET_PASSWORD_TOAST.SUCCESS)
          closeAllModal()
          navigateTo({
            path: PAGE_URLS.HOME,
            query: {
              popup: 'login'
            }
          })
          setTimeout(() => {
            openModal(MODAL_TYPES.LOGIN)
          }, 200)
        } else if (response && response.status === 'VALIDATE_FAILED') {
          $alertMsg('warning', response.message)
        } else {
          $alertMsg('error', RESET_PASSWORD_TOAST.ERROR)
        }
      }
      isLoadingSubmit.value = false
    } catch (error) {
      isLoadingSubmit.value = false
    }
  }

  return {
    submit,
    password,
    confirmPassword,
    passwordRef,
    confirmPasswordRef,
    isLoadingSubmit
  }
}
